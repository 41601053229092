<template>
  <CHeader class="header d-flex pt-1 pb-1">
    <CToggler class="d-s-block d-md-none ml-2 px-3 btn" @click="visible = !visible"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
    </svg></CToggler>
    <CHeaderNav class="d-none d-md-block d-lg-block d-xl-block" :style="visible?'display: flex;': 'display: none;'">
      <CHeaderNavItem class="ml-auto d-md-block d-lg-block d-xl-block">
        <CHeaderNavLink class="p-3" v-on:click.native="toggleProfiModus()">
          <CIcon name="cil-media-skip-backward" v-if="!profiModus"/>
          <CIcon name="cil-media-skip-forward" v-if="profiModus"/>
        </CHeaderNavLink>
    </CHeaderNavItem>
  </CHeaderNav>
    <CHeaderNav class="m-auto" :style="visible?'display: flex;': 'display: none;'">
     <CHeaderNavItem>
       <CHeaderNavLink to="/protokoll" class="px-1">
         <CIcon name="cil-description"/>
         <span class="ml-2" v-if="!profiModus">Protokoll</span>
       </CHeaderNavLink>
     </CHeaderNavItem>
     <CHeaderNavItem>
       <CHeaderNavLink to="/tageskalender" class="px-1">
         <CIcon name="cil-calendar"/>
         <span class="ml-2" v-if="!profiModus">Tageskalender</span>
       </CHeaderNavLink>
     </CHeaderNavItem>

     <CHeaderNavItem>
       <CHeaderNavLink to="/report" class="px-2">
         <CIcon name="cil-chart"/>
         <span class="ml-2" v-if="!profiModus">Report</span>
       </CHeaderNavLink>
     </CHeaderNavItem>
   </CHeaderNav>

   <CHeaderNav class="m-auto" :style="visible?'display: flex;': 'display: none;'">
     <CDropdown
       inNav
       class="c-header-nav-items theheaderdropdown"
       placement="bottom-end"
       add-menu-classes="pt-0"
     >
       <template #toggler>
         <CHeaderNavLink ref="kundendropdown" class="dropdown-toggle">
           <CIcon name="cil-address-book"/>
           <span class="ml-2" v-if="!profiModus">Kunden</span>
         </CHeaderNavLink>
       </template>

       <CDropdownItem to="/kunden/uebersicht">
         <CIcon name="cil-storage" /> Übersicht Kunden
       </CDropdownItem>

       <CDropdownItem to="/kunden/neu">
         <CIcon name="cil-library-add" /> Neuer Kunde
       </CDropdownItem>
     </CDropdown>

     <CHeaderNavItem>
       <CHeaderNavLink to="/projekte" class="px-3">
         <CIcon name="cil-lan"/>
         <span class="ml-2" v-if="!profiModus">Projekte</span>
       </CHeaderNavLink>
     </CHeaderNavItem>
   </CHeaderNav>

    <CHeaderNav class="m-auto" :style="visible?'display: flex;': 'display: none;'">
    <div class="ml-md-5" v-if="this.$roleBasedSecurity.userHasRole(['ROLE_REPORT', 'ROLE_TEAM'])" :style="visible?'display: flex;': 'display: none;'">
      <CDropdown
        inNav
        class="c-header-nav-items theheaderdropdown"
        placement="bottom-end"
        add-menu-classes="pt-0"
      >
        <template #toggler>
          <CHeaderNavLink ref="verwaltungdropdown" class="dropdown-toggle">
            <CIcon name="cil-settings"/>
            <span class="ml-2" v-if="!profiModus">Vorgaben</span>
          </CHeaderNavLink>
        </template>

        <CDropdownItem to="/freizeit/uebersicht" v-if="this.$roleBasedSecurity.userHasRole(['ROLE_REPORT'])">
          <CIcon name="cil-calendar" /> Allg. Freizeit / Fortbildung
        </CDropdownItem>

        <CDropdownItem to="/abwesenheiten/uebersicht" v-if="this.$roleBasedSecurity.userHasRole(['ROLE_REPORT'])">
          <CIcon name="cil-beach-access" /> Abwesenheit / Krankheit
        </CDropdownItem>

        <CDropdownItem to="/leistungen/uebersicht" v-if="this.$roleBasedSecurity.userHasRole(['ROLE_REPORT'])">
          <CIcon name="cil-window" /> Leistungen
        </CDropdownItem>

        <CDropdownItem to="/textbausteine/uebersicht" v-if="this.$roleBasedSecurity.userHasRole(['ROLE_TEAM'])">
          <CIcon name="cil-text" /> Textbausteine
        </CDropdownItem>
      </CDropdown>
      <CDropdown
        v-if="this.$roleBasedSecurity.userHasRole(['ROLE_ADMIN']) || this.$roleBasedSecurity.userHasRole(['ROLE_SUPER_ADMIN'])"
        inNav
        class="c-header-nav-items theheaderdropdown"
        placement="bottom-end"
        add-menu-classes="pt-0"
      >
        <template #toggler>
          <CHeaderNavLink ref="mitarbeiterdropdown" class="dropdown-toggle">
            <CIcon name="cil-people"/>
            <span class="ml-2" v-if="!profiModus">Team</span>
          </CHeaderNavLink>
        </template>

        <CDropdownItem to="/mitarbeiter/uebersicht">
          <CIcon name="cil-storage" /> Übersicht Team
        </CDropdownItem>

        <CDropdownItem to="/mitarbeiter/rollen">
          <CIcon name="cil-storage" /> Rollen und Rechte
        </CDropdownItem>

        <CDropdownItem to="/mitarbeiter/neu">
          <CIcon name="cil-library-add" /> Neuer Mitarbeiter
        </CDropdownItem>
      </CDropdown>
    </div>
    </CHeaderNav>
    <div class="d-md-none break" v-if="visible"></div>
    <div class="d-s-block d-md-none" v-if="visible">
      <button class="d-s-block d-md-none ml-2 mr-2 btn px-3" style="height: 100%" v-on:click="toggleProfiModus()">
        <CIcon name="cil-media-skip-backward" v-if="!profiModus"/>
        <CIcon name="cil-media-skip-forward" v-if="profiModus"/>
      </button>
    </div>
   <CHeaderNav class="ml-auto">
     <CHeaderNavItem>
       <button
         v-on:click="toggleDarkMode()"
         class="c-header-nav-btn"
       >
         <CIcon v-if="this.$store.getters['coreui/darkMode']" name="cil-sun"/>
         <CIcon v-else name="cil-moon"/>
         <span class="ml-2" v-if="!profiModus">Hell/Dunkel</span>
       </button>
     </CHeaderNavItem>
     <TheHeaderAccount/>
   </CHeaderNav>
  </CHeader>
</template>

<script>
import TheHeaderAccount from './TheHeaderAccount'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderAccount
  },
  mounted () {
    this.profiModus = this.$store.getters['persistent/getProfimodus']
  },
  computed: {
    darkMode () {
      return this.$store.getters['coreui/darkMode']
    },
    user () {
      return this.$store.getters['security/user']
    }
  },
  data () {
    return {
      visible: true,
      profiModus: false
    }
  },
  methods: {
    toggleDarkMode () {
      this.$store.commit('coreui/TOGGLE_DARKMODE', !this.darkMode)
    },
    toggleProfiModus () {
      this.profiModus = !this.profiModus
      this.$store.commit('persistent/SET_PROFIMODUS', this.profiModus)
    }
  }
}
</script>

<style lang="scss">
li.theheaderdropdown {
  .c-icon {
    margin-right: 0.3rem;
  }
  a.disabled {
    color: rgba(0, 0, 21, 0.5) !important;
  }
}

.break {
  flex-basis: 100%;
  height: 0;
}
</style>
